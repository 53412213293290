import React, { useContext, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import UserContext from '../context/UserContext';
import { FaTrashAlt } from 'react-icons/fa';
import { Accordion, Button, Modal } from 'flowbite-react';

function Notes({ notes, addNote, deleteNote, stockSymbol }) {
  const [currentUser, setCurrentUser] = useContext(UserContext);
  // const [title, setTitle] = useState('');
  // const [text, setText] = useState('');
  // const [summary, setSummary] = useState('');
  // const [sentiment, setSentiment] = useState('');
  const [noteData, setNoteData] = useState({
    title: '',
    text: '',
    summary: '',
    sentiment: 'Neutral',
    data: {},
  });

  const [data, setData] = useState('');

  const [openModal, setOpenModal] = useState(false);

  function handleSubmit(e) {
    e.preventDefault();
    addNote(noteData);
    setNoteData({
      title: '',
      text: '',
      summary: '',
      sentiment: 'Bullish',
      data: {},
    });
    setOpenModal(false);
  }

  function handleDelete(id, e) {
    e.preventDefault();
    deleteNote(id);
  }

  return (
    <section class="bg-white dark:bg-gray-900 py-8 lg:py-16">
      <div class="mx-auto px-4">
        <div class="flex flex-row justify-between mb-6">
          <h2 class="text-lg lg:text-2xl font-bold text-gray-900 dark:text-white">
            Notes ({notes.length})
          </h2>
          <button
            type="submit"
            class="inline-flex items-center pb-2.5 pl-20 text-white bg-indigo-700 hover:bg-indigo-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
            onClick={() => setOpenModal(true)}
          >
            Add note
          </button>
          <Modal
            size="7xl"
            dismissible
            show={openModal}
            onClose={() => setOpenModal(false)}
          >
            <Modal.Header>Add a Note</Modal.Header>
            <Modal.Body>
              <div className="space-y-6">
                <form
                  class="space-y-4 md:space-y-6"
                  onSubmit={handleSubmit}
                  action="#"
                >
                  <div>
                    <label
                      for="title"
                      class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Title
                    </label>
                    <input
                      name="title"
                      id="title"
                      class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-indigo-500 dark:focus:border-indigo-500"
                      placeholder=""
                      value={noteData.title}
                      onChange={(e) => {
                        setNoteData({ ...noteData, title: e.target.value });
                      }}
                    />
                  </div>
                  <div>
                    <label
                      for="title"
                      class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Note
                    </label>

                    <textarea
                      id="comment"
                      rows="6"
                      value={noteData.text}
                      onChange={(e) => {
                        setNoteData({ ...noteData, text: e.target.value });
                      }}
                      class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-indigo-500 dark:focus:border-indigo-500"
                      required
                    ></textarea>
                  </div>
                  <div>
                    <label
                      for="summary"
                      class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Summary
                    </label>
                    <input
                      name="summary"
                      id="summary"
                      class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-indigo-500 dark:focus:border-indigo-500"
                      placeholder=""
                      value={noteData.summary}
                      onChange={(e) => {
                        setNoteData({ ...noteData, summary: e.target.value });
                      }}
                    />
                  </div>
                  <div>
                    <label
                      for="sentiment"
                      class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Sentiment
                    </label>
                    <select
                      class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                      value={noteData.sentiment}
                      onChange={(e) =>
                        setNoteData({ ...noteData, sentiment: e.target.value })
                      }
                    >
                      <option value="Neutral">Neutral</option>
                      <option value="Bullish">Bullish</option>
                      <option value="Bearish">Bearish</option>
                      <option value="TBD">TBD</option>
                    </select>
                  </div>
                </form>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="submit"
                class="bg-indigo-700 inline-flex items-center pb-2.5 pl-40 text-white hover:bg-indigo-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                onClick={handleSubmit}
              >
                Save
              </button>
              <button
                type="submit"
                class="inline-flex items-center pb-2.5 pl-40 text-indigo-700 bg-gray-300 border border-indigo-700 hover:bg-gray-500 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                onClick={() => setOpenModal(false)}
              >
                Close
              </button>
            </Modal.Footer>
          </Modal>
        </div>
        {/* </form> */}
        {/* {notes.map((n) => (
          <article class="p-6 mb-6 text-base bg-white rounded-lg dark:bg-gray-900">
            <footer class="flex justify-between items-center mb-2">
              <div class="flex items-center">
                <Avatar>{`${currentUser.first_name[0].toUpperCase()}${currentUser.last_name[0].toUpperCase()}`}</Avatar>
                <p class="text-sm text-gray-600 dark:text-gray-400 pl-4 pt-3">
                  <time pubdate>
                    {new Intl.DateTimeFormat('en-US', {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                      hour: '2-digit',
                      minute: '2-digit',
                    }).format(new Date(n.created_on))}
                  </time>
                </p>
              </div>
              <FaTrashAlt onClick={(e) => handleDelete(n.id, e)} />
            </footer>
            <h3 class="text-gray-700 ">{n.title}</h3>
            <p class="text-gray-700 text-1xl whitespace-pre-wrap">{n.text}</p>
          </article>
        ))} */}
        {notes.map((n) => (
          <div className="pb-8">
            <Accordion collapseAll>
              <Accordion.Panel>
                <Accordion.Title>
                  <div class="flex flex-row items-start">
                    <Avatar>{`${currentUser.first_name[0].toUpperCase()}${currentUser.last_name[0].toUpperCase()}`}</Avatar>
                    <h3 class="text-gray-700 pl-3 pt-1">{n.title}</h3>
                    <div class="text-sm text-gray-600 pl-4 pt-3">
                      {/* <time pubdate> */}
                      {new Intl.DateTimeFormat('en-US', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                        hour: '2-digit',
                        minute: '2-digit',
                      }).format(new Date(n.created_on))}
                      {/* </time> */}
                    </div>
                  </div>
                </Accordion.Title>
                <Accordion.Content>
                  <p class="text-gray-700 text-1xl whitespace-pre-wrap">
                    {n.text}
                  </p>
                </Accordion.Content>
              </Accordion.Panel>
            </Accordion>
          </div>
        ))}
      </div>
    </section>
  );
}
export default Notes;
