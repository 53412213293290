import { React, useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import TradesTable from '../components/TradesTable';
import { Table, Modal } from 'flowbite-react';
import { DatePicker } from 'react-rainbow-components';
// import Multiselect from 'multiselect-react-dropdown';
import CreatableSelect from 'react-select/creatable';
import DataTable from '../components/DataTable';

import Spinner from '../components/Spinner';
import useApi from '../hooks/useApi';

export default function Trades() {
  const [trades, setTrades] = useState([]);
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [stockSymbol, setStockSymbol] = useState('');

  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [tradeData, setTradeData] = useState({
    quantity: null,
    price: null,
    trade_type: 'Buy',
    created_on: new Date(),
  });

  const columns = [
    { key: 'stock', value: 'Stock', badge: true, linkPath: '/stocks/' },
    { key: 'tags', value: 'Tags' },
    { key: 'created_on', value: 'Date' },
    { key: 'trade_type', value: 'Type', badge: true },
    { key: 'price', value: 'Price', prefix: '$' },
    { key: 'quantity', value: 'Quantity' },
    { key: 'profit', value: 'Profit', green_red_badge: true, prefix: '$' },
    { key: 'user', value: 'User' },
  ];

  async function addTrade(tradeData) {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/stocks/${stockSymbol}/trade`,
      {
        ...tradeData,
      }
    );

    setTrades([res.data, ...trades]);
  }

  useEffect(() => {
    const getTrades = async () => {
      try {
        const result = await axios.get(
          `${process.env.REACT_APP_API_URL}/trades`
        );

        setLoading(false);
        setTrades(result.data);
      } catch (error) {
        console.log(error);
      }
    };

    const getTags = async () => {
      try {
        const result = await axios.get(`${process.env.REACT_APP_API_URL}/tags`);

        setLoading(false);
        setTags(
          result.data.map((t) => {
            return {
              label: t.tag,
              value: t.tag,
            };
          })
        );
      } catch (error) {
        console.log(error);
      }
    };
    getTrades();
    getTags();
  }, []);

  function handleSubmit(e) {
    e.preventDefault();
    // setTradeData({ ...tradeData});
    console.log(selectedTags);
    addTrade({ ...tradeData, tags: selectedTags.map((t) => t.value) });
    setTradeData({
      quantity: null,
      price: null,
      trade_type: 'Buy',
      created_on: new Date(),
    });
    setSelectedTags([]);

    setOpenModal(false);
  }

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="pt-8">
      <h2 class="text-5xl my-3 text-center text-gray-500">Trades</h2>
      <div className="flex flex-row justify-end pt-8 pb-1">
        <button
          type="submit"
          class="inline-flex items-center pb-2.5 pl-20 text-white bg-indigo-700 hover:bg-indigo-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
          onClick={() => setOpenModal(true)}
        >
          Add Trade
        </button>
        <Modal
          size="5xl"
          dismissible
          show={openModal}
          onClose={() => setOpenModal(false)}
        >
          <Modal.Header>Log a Trade</Modal.Header>
          <Modal.Body>
            <div className="space-y-6">
              <form
                class="space-y-4 md:space-y-6"
                onSubmit={handleSubmit}
                action="#"
              >
                <div>
                  <label
                    for="title"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Stock
                  </label>
                  <input
                    name="quantity"
                    id="quantity"
                    class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-indigo-500 dark:focus:border-indigo-500"
                    placeholder=""
                    value={stockSymbol}
                    type="text"
                    onChange={(e) => {
                      setStockSymbol(e.target.value);
                    }}
                  />
                </div>

                <div>
                  <label
                    for="title"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Date
                  </label>
                  <DatePicker
                    id="datePicker-1"
                    value={tradeData.created_on}
                    formatStyle="large"
                    onChange={(date) => {
                      console.log(date);
                      console.log(
                        date.toISOString().slice(0, 19).replace('T', ' ')
                      );
                      setTradeData({
                        ...tradeData,
                        created_on: date
                          .toISOString()
                          .slice(0, 19)
                          .replace('T', ' '),
                      });
                    }}
                  />
                </div>
                <div>
                  <label
                    for="title"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Tags
                  </label>
                  <CreatableSelect
                    isMulti
                    options={tags}
                    onChange={(newValue) => setSelectedTags(newValue)}
                    onCreateOption={(newTag) => {
                      setTags([{ label: newTag, value: newTag }, ...tags]);
                      setSelectedTags([
                        ...selectedTags,
                        { label: newTag, value: newTag },
                      ]);
                    }}
                    value={selectedTags}
                  />
                </div>
                <div>
                  <label
                    for="title"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Number of Shares
                  </label>
                  <input
                    name="quantity"
                    id="quantity"
                    class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-indigo-500 dark:focus:border-indigo-500"
                    placeholder=""
                    value={tradeData.quantity}
                    type="number"
                    onChange={(e) => {
                      setTradeData({ ...tradeData, quantity: e.target.value });
                    }}
                  />
                </div>
                <div>
                  <label
                    for="title"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Price
                  </label>
                  <input
                    name="price"
                    id="price"
                    class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-indigo-500 dark:focus:border-indigo-500"
                    placeholder=""
                    value={tradeData.price}
                    type="number"
                    onChange={(e) => {
                      setTradeData({ ...tradeData, price: e.target.value });
                    }}
                  />
                </div>
                <div>
                  <label
                    for="sentiment"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Trade Type
                  </label>
                  <select
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                    value={tradeData.sentiment}
                    onChange={(e) =>
                      setTradeData({ ...tradeData, trade_type: e.target.value })
                    }
                  >
                    <option value="Buy">Buy</option>
                    <option value="Sell">Sell</option>
                  </select>
                </div>
              </form>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="submit"
              class="bg-indigo-700 inline-flex items-center pb-2.5 pl-40 text-white hover:bg-indigo-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              onClick={handleSubmit}
            >
              Save
            </button>
            <button
              type="submit"
              class="inline-flex items-center pb-2.5 pl-40 text-indigo-700 bg-gray-300 border border-indigo-700 hover:bg-gray-500 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              onClick={() => setOpenModal(false)}
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>
      </div>
      {/* <TradesTable trades={trades} showSymbol={true} /> */}
      <DataTable rows={trades} columns={columns} />
    </div>
  );
}
