import { React, useState } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import { Table, Modal } from 'flowbite-react';
// import { Badge } from 'react-rainbow-components';
import { Badge, DatePicker } from 'react-rainbow-components';
// import {
//   Popover,
//   PopoverHandler,
//   PopoverContent,
//   Button,
// } from '@material-tailwind/react';
import { Popover, Whisper, Button, Toggle } from 'rsuite';
import Avatar from './Avatar';

import Spinner from '../components/Spinner';
import useApi from '../hooks/useApi';

export default function TradesTable({ header, columns, rows }) {
  // render cell based on column key and whether link or badge is needed

  function getNestedValue(obj, keys) {
    if (typeof keys === 'string') {
      keys = keys.split('.');
    }
    try {
      for (const key of keys) {
        obj = obj[key];
      }
      // console.log(obj);
      return obj;
    } catch (error) {
      return undefined;
    }
  }

  function renderCell(row, column) {
    let inner = (
      <Table.Cell className="items-center text-center">
        {row[column.key]}
      </Table.Cell>
    );

    if (column.key === 'user') {
      inner = (
        <Table.Cell className="items-center text-center">
          <Avatar
            first_name={row.user.first_name}
            last_name={row.user.last_name}
          />
        </Table.Cell>
      );
    } else if (column.key === 'created_on') {
      inner = (
        <Table.Cell className="items-center text-center">
          {new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          }).format(new Date(row.created_on))}
        </Table.Cell>
      );

      // display green or red badge
    } else if (column.green_red_badge === true) {
      let variant = 'default';

      if (row[column.key]) {
        if (row[column.key] != 0) {
          variant = row[column.key] > 0 ? 'success' : 'error';
        }
      }
      inner = (
        <Table.Cell className="items-center text-center">
          {' '}
          <Badge
            className="rainbow-m-around_medium font-semibold"
            variant={variant}
          >
            {row[column.key] < 0 && '-'}
            {row[column.key] && column.prefix}
            {Math.abs(row[column.key])}
            {row[column.key] && column.defix}
          </Badge>
        </Table.Cell>
      );
    } else if (column.multiImages === true) {
      inner =
        row.watchlist_stocks !== null ? (
          <Table.Cell className="">
            {/* <div className="text-sm text-gray-900">
              <div class="flex flex-row space-x-4"> */}
            <div class="flex -space-x-4 justify-center rtl:space-x-reverse">
              <>
                {row.watchlist_stocks.slice(0, 5).map((ws) => (
                  <img
                    class="w-12 h-12 border border-black rounded-full dark:border-gray-800"
                    src={ws.stock.logo}
                    alt=""
                  />
                ))}

                {row.watchlist_stocks.length > 5 ? (
                  <>
                    <a
                      class="flex items-center justify-center w-12 h-12 text-xs font-medium text-white bg-gray-700 border border-white rounded-full hover:bg-gray-600"
                      href={`/watchlists/${row.id}`}
                    >
                      {row.watchlist_stocks.length - 5}+
                    </a>
                  </>
                ) : (
                  <></>
                )}
              </>
            </div>
            {/* </div> */}
          </Table.Cell>
        ) : (
          <Table.Cell>-</Table.Cell>
        );
    } else if (column.keys) {
      inner = (
        <Table.Cell className="items-center text-center items-center text-black text-center font-bold">
          {getNestedValue(row, column.keys)}
        </Table.Cell>
      );
    } else if (column.key == 'tags') {
      const tags = row[column.key];

      if (tags.length > 0) {
        let innerContent = tags.map((tag) => {
          return (
            <div className="pb-2 text-center items-center">
              <Badge
                className="rainbow-m-around_medium font-semibold"
                variant={'default'}
              >
                {tag.tag}
              </Badge>
            </div>
          );
        });
        const content = <div className="flex flex-col">{innerContent}</div>;
        const popover = <Popover title="Tags">{content}</Popover>;

        inner = (
          <Table.Cell className="items-center text-center">
            <Whisper
              trigger="click"
              placement="top"
              controlId="control-id-1"
              speaker={popover}
            >
              <Button appearance="subtle">View Tags</Button>
            </Whisper>
          </Table.Cell>
        );
      } else {
        <Table.Cell className="items-center text-center"></Table.Cell>;
      }
    } else if (column.key == 'callback') {
      inner = (
        <Table.Cell
          className="items-center text-center text-blue-600 cursor-pointer"
          onClick={(e) => column.onClick(e, getNestedValue(row, column.params))}
        >
          Delete
        </Table.Cell>
      );
    } else if (column.key == 'logo') {
      inner = (
        <Table.Cell>
          <div className="flex flex-row justify-center">
            <img
              class="w-12 h-12 border border-black rounded-full dark:border-gray-800"
              src={row.stock.logo}
            />
          </div>
        </Table.Cell>
      );
    }

    if (column.linkPath) {
      const key = column.linkKey ? row[column.linkKey] : row[column.key];
      inner = (
        <div className="items-center text-black text-center pt-2 font-bold">
          <Link to={`${column.linkPath}${key}`} style={{ color: 'black' }}>
            {inner}
          </Link>
        </div>
      );
    }

    if (column.linkKeys) {
      // console.log(inner);
      // console.log(`${column.linkPaths}${getNestedValue(row, column.linkKeys)}`);
      inner = (
        <div className="items-center text-black text-center font-bold">
          <Link
            to={`${column.linkPaths}${getNestedValue(row, column.linkKeys)}`}
            style={{ color: 'black' }}
          >
            {inner}
          </Link>
        </div>
      );
    }

    // console.log(inner);
    return inner;
  }

  return (
    <div className="">
      <h2 class="text-5xl my-3 text-center text-gray-500">{header}</h2>
      <div className="overflow-x-auto">
        <Table>
          <Table.Head>
            {columns.map((column) => {
              return (
                <Table.HeadCell className="items-center text-center">
                  {column.value}
                </Table.HeadCell>
              );
            })}
          </Table.Head>
          <Table.Body className="divide-y">
            {rows.map((row) => (
              <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                {columns.map((column) => {
                  return renderCell(row, column);
                })}
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
    </div>
  );
}
