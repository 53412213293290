import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

function SpinnerComponent() {
  return (
    <div
      style={{ width: '200px', height: '200px' }}
      className="w-100 d-flex justify-content-center align-items-center"
    >
      <Spinner
        animation="border"
        role="status"
        style={{ width: '4rem', height: '4rem' }}
        variant="primary"
        class="text-center"
      />
    </div>
  );
}

export default SpinnerComponent;
