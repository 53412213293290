import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Container,
  Navbar,
  Nav,
  NavDropdown,
  Form,
  FormControl,
  Button,
} from 'react-bootstrap';
import Avatar from './Avatar';
import UserContext from '../context/UserContext';

function Layout(props) {
  const [currentUser, setCurrentUser] = useContext(UserContext);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  const handleLogOut = () => {
    localStorage.removeItem('user');
    setCurrentUser({});
    navigate('/login');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate(`/stocks/${searchTerm.toUpperCase()}`);
    window.location.reload();
  };

  return (
    <>
      <body>
        <Navbar bg="light" variant="lg">
          <Container>
            <Navbar.Brand href="/stocks">
              <img
                alt=""
                src="/candlestick.png"
                width="30"
                height="30"
                className="d-inline-block mr-3 pb-1"
              />{' '}
              Strikezone
            </Navbar.Brand>
            {currentUser?.token ? (
              <>
                <Nav className="me-auto">
                  <Nav.Link href="/stocks">Stocks</Nav.Link>
                  <Nav.Link href="/notes">Notes</Nav.Link>
                  <Nav.Link href="/positions">Positions</Nav.Link>
                  <Nav.Link href="/trades">Trades</Nav.Link>
                  <Nav.Link href="/watchlists">Watchlists</Nav.Link>
                  <NavDropdown title="More" id="basic-nav-dropdown">
                    <Nav.Link href="/todo">TODO</Nav.Link>
                  </NavDropdown>
                </Nav>

                <Form
                  onSubmit={handleSubmit}
                  inline
                  className="ml-auto flex flex-row mr-8"
                >
                  <FormControl
                    type="text"
                    placeholder="Search"
                    className="mr-sm-2"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </Form>

                <Avatar
                  first_name={currentUser.first_name}
                  last_name={currentUser.last_name}
                  color={currentUser.color}
                />
                <Button variant="" pullRight onClick={handleLogOut}>
                  Logout
                </Button>
              </>
            ) : (
              <Nav.Link href="/login" pullRight>
                Login
              </Nav.Link>
            )}
          </Container>
        </Navbar>
        <Container>{props.children}</Container>
      </body>

      <footer class="pt-[32rem]"></footer>
    </>
  );
}

export default Layout;
