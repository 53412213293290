let now = new Date();
let start = new Date(now.getFullYear(), 0, 0);
let diff = now - start;
let oneDay = 1000 * 60 * 60 * 24;
let ytdDays = Math.floor(diff / oneDay);

export const chartConfig = {
  // "1D": { resolution: "1", days: 1, weeks: 0, months: 0, years: 0 },
  '1W': { resolution: '15', days: 0, weeks: 1, months: 0, years: 0 },
  '1M': { resolution: '60', days: 0, weeks: 0, months: 1, years: 0 },
  YTD: { resolution: '15', days: ytdDays, weeks: 0, months: 0, years: 0 },
  '1Y': { resolution: 'Y', days: 0, weeks: 0, months: 0, years: 1 },
  '3Y': { resolution: 'Y', days: 0, weeks: 0, months: 0, years: 3 },
  '5Y': { resolution: 'Y', days: 0, weeks: 0, months: 0, years: 5 },
};
