export default function getAvatarColor(firstName, lastName) {
  // Combine first name and last name to generate a unique color
  const fullName = `${firstName}${lastName}`;

  if (fullName.toLowerCase() == 'patbrennan') {
    // Return the specific color for Patrick Brennan
    return '#4338CA'; // Indigo-800 color code
  }
  // Calculate a hash code for the combined name
  let hash = 0;
  for (let i = 0; i < fullName.length; i++) {
    hash = fullName.charCodeAt(i) + ((hash << 5) - hash);
  }

  // Convert the hash code to a color code (hex)
  const color =
    '#' +
    Math.floor(Math.abs(Math.sin(hash) * 16777215) % 16777215).toString(16);

  return color;
}
