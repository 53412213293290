import { useContext } from 'react';

import { useNavigate } from 'react-router-dom';
import UserContext from '../context/UserContext';

const PrivateRoute = ({ children }) => {
  const [currentUser, setCurrentUser] = useContext(UserContext);
  const navigate = useNavigate();
  if (!currentUser?.token) {
    return navigate('/login');
  }
  return children;
};
export default PrivateRoute;
