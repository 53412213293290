import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router';
import Overview from '../components/Overview';
import Details from '../components/Details';
import Chart from '../components/Chart';
import Header from '../components/Header';
import Notes from '../components/Notes';
import Trades from '../components/Trades';
import DataTable from '../components/DataTable';
// import { Tabs } from 'flowbite-react';
import { Button, Checkbox, Label, TextInput } from 'flowbite-react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Card from '../components/Card';
import {
  fetchStockDetails,
  fetchQuote,
  fetchMetrics,
} from '../utils/api/stock-api';
import StockMetricsChart from '../components/StockMetricsChart';

const Dashboard = () => {
  const stockSymbol = useParams()['symbol'].toUpperCase();

  const [key, setKey] = useState('overview');
  const [stockDetails, setStockDetails] = useState({});
  const [notes, setNotes] = useState([]);
  const [trades, setTrades] = useState([]);
  const [stockData, setStockData] = useState({});
  const [quote, setQuote] = useState({});
  const [stockMetrics, setStockMetrics] = useState({});

  const columns = [
    { key: 'created_on', value: 'Date' },
    { key: 'trade_type', value: 'Type', badge: true },
    { key: 'price', value: 'Price', prefix: '$' },
    { key: 'quantity', value: 'Quantity' },
    { key: 'profit', value: 'Profit', green_red_badge: true, prefix: '$' },
    { key: 'tags', value: 'Tags' },
    { key: 'user', value: 'User' },
  ];

  async function addNote(noteData) {
    const newNoteRes = await axios.post(
      `${process.env.REACT_APP_API_URL}/notes/${stockSymbol}`,
      {
        ...noteData,
      }
    );

    setNotes([newNoteRes.data, ...notes]);
  }

  async function addTrade(tradeData) {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/stocks/${stockSymbol}/trade`,
      {
        ...tradeData,
      }
    );

    setTrades([...trades, res.data]);
  }

  async function deleteNote(id) {
    const deleteNoteRes = await axios.delete(
      `http://localhost:5002/api/notes/${id}`
    );
    const fileteredNotes = notes.filter((n) => n.id !== id);
    setNotes([...fileteredNotes]);
  }

  useEffect(() => {
    const updateStockDetails = async () => {
      try {
        const result = await fetchStockDetails(stockSymbol);
        setStockDetails(result);
      } catch (error) {
        setStockDetails({});
        console.log(error);
      }
    };

    const updateStockOverview = async () => {
      try {
        const result = await fetchQuote(stockSymbol);
        setQuote(result);
      } catch (error) {
        setQuote({});
        console.log(error);
      }
    };

    const getStockData = async () => {
      try {
        const result = await axios.get(
          `${process.env.REACT_APP_API_URL}/stocks/${stockSymbol}`
        );
        const [data] = result.data;
        setStockData(data);
        setNotes(data.notes);
        setTrades(data.trades);
      } catch (error) {
        console.log(error);
      }
    };

    const getStockMetrics = async () => {
      try {
        const result = await fetchMetrics(stockSymbol);
        setStockMetrics(result);
      } catch (error) {
        setStockMetrics({});
        console.log(error);
      }
    };

    getStockMetrics();
    updateStockOverview();
    updateStockDetails();
    getStockData();
  }, []);

  if (quote.c && notes) {
    return (
      <div className="pt-10">
        <div className="flex flex-row">
          <Header
            stockData={{
              ...stockData,
              price: quote.c,
              changePercent: quote.dp,
              change: quote.d,
            }}
          />
        </div>
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="mb-1 mt-4"
        >
          <Tab eventKey="overview" title="Overview">
            <div className="w-full pt-3">
              <div>
                <Chart stockSymbol={stockSymbol} currentPrice={quote.c} />
              </div>

              <Card title="Metrics">
                <div className="grid grid-cols-3 gap-4">
                  <div>
                    <h3 className="text-xl font-semibold">Valuation</h3>
                    <p>
                      <strong>P/E (TTM):</strong> {stockMetrics.metric?.peTTM}
                    </p>
                    <p>
                      <strong>P/B:</strong>{' '}
                      {stockMetrics.metric?.bookValuePerShareAnnual}
                    </p>
                    <p>
                      <strong>Dividend:</strong>{' '}
                      {stockMetrics.metric?.currentDividendYieldTTM}
                    </p>

                    <p>
                      <strong>Cash Per Share:</strong>{' '}
                      {stockMetrics.metric?.cashPerSharePerShareQuarterly}
                    </p>
                  </div>
                  <div>
                    <h3 className="text-xl font-semibold">Growth</h3>
                    <p>
                      <strong>Earnings Growth (3Y):</strong>{' '}
                      {stockMetrics.metric?.epsGrowth3Y}%
                    </p>
                    <p>
                      <strong>Earnings Growth (5Y):</strong>{' '}
                      {stockMetrics.metric?.epsGrowth5Y}%
                    </p>
                    <p>
                      <strong>Revenue Growth (3Y):</strong>{' '}
                      {stockMetrics.metric?.revenueGrowth3Y}%
                    </p>
                    <p>
                      <strong>Revenue Growth (5Y):</strong>{' '}
                      {stockMetrics.metric?.revenueGrowth5Y}%
                    </p>
                  </div>
                  <div>
                    <h3 className="text-xl font-semibold">Return</h3>
                    <p>
                      <strong>13-Week:</strong>{' '}
                      {stockMetrics.metric?.['13WeekPriceReturnDaily']}%
                    </p>
                    <p>
                      <strong>26-Week:</strong>{' '}
                      {stockMetrics.metric?.['26WeekPriceReturnDaily']}%
                    </p>
                    <p>
                      <strong>52-Week:</strong>{' '}
                      {stockMetrics.metric?.['52WeekPriceReturnDaily']}%
                    </p>
                  </div>
                </div>
              </Card>
            </div>
          </Tab>
          <Tab eventKey="metrics" title="Metrics">
            <StockMetricsChart stockMetrics={stockMetrics} />
          </Tab>
          <Tab eventKey="notes" title="Notes">
            <Notes
              notes={notes}
              addNote={addNote}
              deleteNote={notes}
              stockSymbol={stockSymbol}
            />
          </Tab>
          <Tab eventKey="trades" title="Trades">
            {/* <Trades trades={trades} addTrade={addTrade} /> */}

            <DataTable rows={trades} columns={columns} />
          </Tab>
        </Tabs>
      </div>
    );
  }
};

export default Dashboard;
