import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';

const StockMetricsChart = ({ stockMetrics }) => {
  if (!stockMetrics || !stockMetrics.series || !stockMetrics.series.annual) {
    return <div>No data available</div>;
  }

  if (!stockMetrics || !stockMetrics.series || !stockMetrics.series.annual) {
    return <div>No data available</div>;
  }

  const { annual } = stockMetrics.series;

  // Convert the annual object into an array of objects with period, eps, ebitPerShare, pe, and ps properties
  let data = [];
  const colors = ['#4338CA', '#5DFDCB', '#55B5FF', '#909496'];
  const metricKeys = ['ebitPerShare', 'eps', 'pe', 'ps'];
  for (const key of metricKeys) {
    const values = annual[key];

    values.forEach(({ period, v }, index) => {
      const existingEntryIndex = data.findIndex(
        (entry) => entry.period === period
      );

      // If an entry exists, update the existing entry
      if (existingEntryIndex !== -1) {
        data[existingEntryIndex][key] = v;
      }
      // If no entry exists, create a new entry
      else {
        const newEntry = { period };
        newEntry[key] = v;
        data.push(newEntry);
      }
    });
  }
  data = data.reverse();
  console.log(data);

  return (
    <div className="flex flex-col mx-auto items-center justify-center text-center">
      <h2>Key Metics</h2>
      <LineChart
        width={1000}
        height={600}
        data={data}
        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
      >
        <XAxis dataKey="period" />
        <YAxis />
        <Tooltip />
        <Legend />
        {metricKeys.map((key, index) => (
          <Line
            key={index}
            type="monotone"
            dataKey={key}
            stroke={colors[index % colors.length]}
            dot={{ fill: colors[index % colors.length] }}
          />
        ))}
      </LineChart>
    </div>
  );
};

export default StockMetricsChart;
