import { React, useState, useEffect } from 'react';
import axios from 'axios';
import Card from '../components/Card';

import { Badge, Table, Modal } from 'flowbite-react';
import Avatar from '../components/Avatar';

import Spinner from '../components/Spinner';
import { useNavigate, Link, useParams } from 'react-router-dom';

function NoteDetail() {
  const [note, setNote] = useState([]);
  const [loading, setLoading] = useState(true);

  const id = useParams()['id'];

  useEffect(() => {
    const getNotes = async () => {
      try {
        const result = await axios.get(
          `${process.env.REACT_APP_API_URL}/note/${id}`
        );

        setLoading(false);
        setNote(result.data);
      } catch (error) {
        console.log(error);
      }
    };
    getNotes();
  }, []);
  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="mx-auto pt-10">
      <div className="flex flex-row justify-start items-center pb-8 pt-12">
        <div className="mr-12 items-cetner">
          <Badge color="gray">{note.stock}</Badge>
        </div>
        {/* <div className="mr-12">{note.sentiment}</div> */}

        {note.sentiment == 'Bullish' ? (
          <div className="flex-shrink-0 h-10 w-10 mr-12 items-center">
            <img src="/bull.png" alt="" />
          </div>
        ) : note.sentiment == 'Bearish' ? (
          <div className="flex-shrink-0 h-10 w-10 mr-12 items-center">
            <img src="/bear.png" alt="" />
          </div>
        ) : (
          <div className=" mr-12 ">
            <Badge color="gray">Undecided</Badge>
          </div>
        )}

        <div className="mr-12">
          <Avatar
            first_name={note.user.first_name}
            last_name={note.user.last_name}
          />
        </div>
        <div>
          {' '}
          {new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          }).format(new Date(note.created_on))}
        </div>
      </div>

      <h2 class="text-5xl pb-3 text-center">{note.title}</h2>
      <div style={{ whiteSpace: 'pre-wrap' }} class="text-black text-xl">
        {note.text}
      </div>
      <h6 class="mt-5 mb-1 text-black text-xl">Summary</h6>
      {note.summary}
    </div>
  );
}

export default NoteDetail;
