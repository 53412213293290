import React, { useContext } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import HomePage from './routes/HomePage';
import Login from './routes/Login';
import UserContext from './context/UserContext';
import PrivateRoute from './components/PrivateRoute';
import SignUp from './routes/SignUp';
import NotFound from './routes/NotFound';
import Watchlist from './routes/Watchlists';
import Stocks from './routes/Stocks';
import StockDetail from './routes/StockDetail';
import WatchlistDetail from './routes/WatchlistDetail';
import Trades from './routes/Trades';
import Positions from './routes/Positions';
import 'bootstrap/dist/css/bootstrap.min.css';
import Notes from './routes/Notes';
import NoteDetail from './routes/NoteDetail';
import DataTable from './components/DataTable';
import Layout from './components/Layout';

import 'rsuite/dist/rsuite.min.css';

export default function App() {
  const [currentUser, setCurrentUser] = useContext(UserContext);

  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route
            path="/"
            element={
              <PrivateRoute>
                <HomePage />
              </PrivateRoute>
            }
          />

          <Route
            path="/stocks"
            element={
              <PrivateRoute>
                <Stocks />
              </PrivateRoute>
            }
          />

          <Route
            path="/stocks/:symbol"
            element={
              <PrivateRoute>
                <StockDetail />
              </PrivateRoute>
            }
          />

          <Route
            path="/trades"
            element={
              <PrivateRoute>
                <Trades />
              </PrivateRoute>
            }
          />

          <Route
            path="/positions"
            element={
              <PrivateRoute>
                <Positions />
              </PrivateRoute>
            }
          />

          <Route
            path="/notes"
            element={
              <PrivateRoute>
                <Notes />
              </PrivateRoute>
            }
          />
          <Route
            path="/notes/:id"
            element={
              <PrivateRoute>
                <NoteDetail />
              </PrivateRoute>
            }
          />

          <Route
            path="/data"
            element={
              <PrivateRoute>
                <DataTable />
              </PrivateRoute>
            }
          />

          <Route
            path="/watchlists"
            element={
              <PrivateRoute>
                <Watchlist />
              </PrivateRoute>
            }
          />
          <Route
            path="/watchlists/:watchlistId"
            element={
              <PrivateRoute>
                <WatchlistDetail />
              </PrivateRoute>
            }
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
}
