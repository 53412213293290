import { React, useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import DataTable from '../components/DataTable';
import { Badge, Table, Modal } from 'flowbite-react';
import CreatableSelect from 'react-select/creatable';

import Spinner from '../components/Spinner';
import useApi from '../hooks/useApi';

export default function Watchlist() {
  const [watchlist, setWatchlist] = useState([]);
  const [loading, setLoading] = useState(true);
  const watchlistId = useParams()['watchlistId'];

  const [stocks, setStocks] = useState([]);
  const [selectedStocks, setSelectedStocks] = useState([]);

  const [watchlistData, setWatchlistData] = useState({
    name: '',
  });

  const [data, setData] = useState('');

  const [openModal, setOpenModal] = useState(false);

  async function addWatchlist(watchlistData) {
    const newWatchlistRes = await axios.post(
      `${process.env.REACT_APP_API_URL}/watchlists/${watchlistId}/add`,
      selectedStocks.map((s) => s.value)
    );

    setWatchlist(newWatchlistRes.data);
  }

  function handleSubmit(e) {
    e.preventDefault();
    addWatchlist(watchlistData);
    setSelectedStocks([]);
    setOpenModal(false);
  }

  async function handleDelete(e, stock) {
    e.preventDefault();
    setLoading(true);
    const res = await axios.delete(
      `${process.env.REACT_APP_API_URL}/watchlists/${watchlistId}/${stock}/delete`
    );

    // watchlist.stocks = watchlist.stocks.filter(
    //   (stock) => stock.symbol != stock
    // );
    setLoading(false);
    setWatchlist(res.data);
  }

  const columns = [
    {
      value: '',
      key: 'logo',
    },
    {
      key: 'name',
      keys: ['stock', 'name'],
      value: 'Company',
      linkPaths: '/stocks/',
      linkKeys: ['stock', 'symbol'],
    },

    { key: 'price', value: 'Price when Added' },
    { key: 'current_price', value: 'Current Price' },
    { key: 'percent_gain', value: '% Gain', green_red_badge: true, defix: '%' },
    { key: 'actively_held', value: 'Active Position' },
    {
      key: 'callback',
      value: '',
      onClick: handleDelete,
      params: ['stock', 'symbol'],
    },
  ];

  useEffect(() => {
    const getWatchlist = async () => {
      try {
        const result = await axios.get(
          `${process.env.REACT_APP_API_URL}/watchlists/${watchlistId}`
        );

        setLoading(false);
        setWatchlist(result.data);
      } catch (error) {
        console.log(error);
      }
    };
    getWatchlist();
  }, []);

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="pt-8">
      <h2 class="text-5xl my-3 text-center text-gray-500">{watchlist.name}</h2>
      <div class="flex flex-row justify-end mb-6">
        <button
          type="submit"
          class="inline-flex items-center pb-2.5 pl-20 text-white bg-indigo-700 hover:bg-indigo-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
          onClick={() => setOpenModal(true)}
        >
          Add stock
        </button>
        <Modal
          size="7xl"
          dismissible
          show={openModal}
          onClose={() => setOpenModal(false)}
        >
          <Modal.Header>Add a Stock</Modal.Header>
          <Modal.Body>
            <div className="space-y-6">
              <form
                class="space-y-4 md:space-y-6"
                onSubmit={handleSubmit}
                action="#"
              >
                <div>
                  <label
                    for="title"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Stocks
                  </label>
                  <CreatableSelect
                    isMulti
                    options={stocks}
                    onChange={(newValue) => setSelectedStocks(newValue)}
                    onCreateOption={(newStock) => {
                      setStocks([
                        { label: newStock, value: newStock },
                        ...stocks,
                      ]);
                      setSelectedStocks([
                        ...selectedStocks,
                        { label: newStock, value: newStock },
                      ]);
                    }}
                    value={selectedStocks}
                  />
                </div>
              </form>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="submit"
              class="bg-indigo-700 inline-flex items-center pb-2.5 pl-40 text-white hover:bg-indigo-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              onClick={handleSubmit}
            >
              Save
            </button>
            <button
              type="submit"
              class="inline-flex items-center pb-2.5 pl-40 text-indigo-700 bg-gray-300 border border-indigo-700 hover:bg-gray-500 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              onClick={() => setOpenModal(false)}
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>
      </div>
      <DataTable rows={watchlist.watchlist_stocks} columns={columns} />
    </div>
  );
}
