import { React, useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import TradesTable from '../components/TradesTable';
import DataTable from '../components/DataTable';

import Spinner from '../components/Spinner';
import useApi from '../hooks/useApi';

export default function Positions() {
  const [trades, setTrades] = useState([]);
  const [loading, setLoading] = useState(true);

  const columns = [
    {
      key: 'stock',
      value: 'Stock',
      badge: true,
      linkPath: '/stocks/',
      linkKey: 'stock',
    },
    { key: 'tags', value: 'Tags' },
    { key: 'quantity', value: 'Quantity', badge: true },
    { key: 'percent_gain', value: '% Gain', green_red_badge: true, defix: '%' },
    { key: 'profit', value: 'Profit', green_red_badge: true, prefix: '$' },
    // { key: 'user', value: 'User' },
  ];

  useEffect(() => {
    const getTrades = async () => {
      try {
        const result = await axios.get(
          `${process.env.REACT_APP_API_URL}/positions`
        );

        setLoading(false);
        console.log(result.data);
        setTrades(result.data);
        // setTrades(result.data.filter((t) => t.quantity_left > 0));
      } catch (error) {
        console.log(error);
      }
    };
    getTrades();
  }, []);

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="pt-8">
      <h2 class="text-5xl my-3 text-center text-gray-500">Active Positions</h2>
      {/* <TradesTable trades={trades} activeOnly={true} showSymbol={true} /> */}
      <DataTable rows={trades} columns={columns} />
    </div>
  );
}
