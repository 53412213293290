import React, { useContext } from 'react';
import getAvatarColor from '../utils/helpers/avatar-helper';

function Avatar({ first_name, last_name }) {
  return (
    <div
      className="relative inline-flex items-center justify-center"
      style={{
        backgroundColor:
          first_name && last_name
            ? getAvatarColor(first_name, last_name)
            : '#000', // Default color if properties are undefined
        width: '40px',
        height: '40px',
        borderRadius: '50%',
      }}
    >
      <span className="font-medium text-white">
        {first_name && last_name
          ? `${first_name[0].toUpperCase()}${last_name[0].toUpperCase()}`
          : ''}
      </span>
    </div>
  );
}

export default Avatar;
