// ${process.env.REACT_APP_API_URL}/login

import { React, useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Table } from 'flowbite-react';
import Spinner from '../components/Spinner';

export default function Stocks() {
  const [stocks, setStocks] = useState([]);
  const [loading, setLoading] = useState(true);

  const [term, setTerm] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    async function getStocks() {
      try {
        const result = await axios.get(
          `${process.env.REACT_APP_API_URL}/stocks`
        );

        setLoading(false);
        setStocks(result.data);
      } catch (error) {
        console.log(error);
      }
    }
    getStocks();
  }, []);

  async function handleSubmit(e) {
    e.preventDefault();
    const searchRes = await axios.get(
      `${process.env.REACT_APP_API_URL}/stocks/?term=${term}`
    );
    setStocks(searchRes.data);
  }

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="mx-auto">
      {/* <h2 class="text-5xl my-3 pb-3 text-center text-gray-500">Stocks</h2> */}
      <form class="pt-10 pb-8 w-2/3 mx-auto" onSubmit={handleSubmit}>
        <input
          value={term}
          onChange={(e) => setTerm(e.target.value)}
          type="text"
          id="simple-search"
          class="bg-gray-50 border border-gray-300 item-center mx-auto text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Search"
        />
      </form>
      <div className="overflow-x-auto">
        <Table>
          <Table.Head>
            <Table.HeadCell className="items-center text-center font-bold"></Table.HeadCell>
            <Table.HeadCell className="items-center text-center font-bold">
              Company
            </Table.HeadCell>
            <Table.HeadCell className="items-center text-center font-bold">
              Symbol
            </Table.HeadCell>
            <Table.HeadCell className="items-center text-center font-bold">
              Industry
            </Table.HeadCell>
          </Table.Head>
          <Table.Body className="divide-y">
            {stocks.map((stock) => (
              <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                  <Link
                    to={`/stocks/${stock.symbol}`}
                    style={{ color: '#FFF' }}
                  >
                    <img className="rounded-full h-10 w-10" src={stock.logo} />{' '}
                  </Link>
                </Table.Cell>
                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                  {' '}
                  <div className="items-center text-center">
                    <Link
                      to={`/stocks/${stock.symbol}`}
                      // style={{ color: '#FFF' }}
                      className="text-gray-900 no-underline"
                    >
                      {stock.name}
                    </Link>
                  </div>
                </Table.Cell>
                <Table.Cell>
                  {' '}
                  <div className="items-center text-center font-bold">
                    <Link
                      to={`/stocks/${stock.symbol}`}
                      // style={{ color: '#FFF' }}
                      className="text-gray-500 no-underline"
                    >
                      {stock.symbol}
                    </Link>
                  </div>
                </Table.Cell>
                <Table.Cell>
                  {' '}
                  <div className="items-center text-center">
                    {stock.finnhubindustry}
                  </div>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
    </div>
  );
}
