import React, { useContext, useEffect, useState } from 'react';
import ChartFilter from './ChartFilter';
import Card from './Card';
import {
  Area,
  XAxis,
  YAxis,
  ResponsiveContainer,
  AreaChart,
  Tooltip,
  Text,
} from 'recharts';
import { fetchHistoricalData } from '../utils/api/stock-api';
import {
  createDate,
  convertDateToUnixTimestamp,
  convertUnixTimestampToDate,
} from '../utils/helpers/date-helper';
import { chartConfig } from '../constants/config';

const Chart = ({ stockSymbol, currentPrice }) => {
  const [filter, setFilter] = useState('YTD');
  const [data, setData] = useState([]);

  const formatData = (result) => {
    const data = result.results.map((info) => {
      return {
        value: info.c.toFixed(2),
        date: convertUnixTimestampToDate(info.t),
      };
    });

    if (new Date().getDay() > 0 && new Date().getDay() < 6) {
      data.push({
        value: currentPrice.toFixed(2),
        date: new Date().toLocaleDateString(),
      });
    }
    return data;
  };

  useEffect(() => {
    const getDateRange = () => {
      const { days, weeks, months, years } = chartConfig[filter];
      const endDate = new Date();
      endDate.setDate(endDate.getDate() - 1);
      const startDate = createDate(
        endDate,
        -(days + 1),
        -weeks,
        -months,
        -years
      );
      const formattedStartDate = startDate.toISOString().split('T')[0];
      const formattedEndDate = endDate.toISOString().split('T')[0];
      return { startDate: formattedStartDate, endDate: formattedEndDate };
    };

    const updateChartData = async () => {
      try {
        const { startDate, endDate } = getDateRange();
        const resolution = chartConfig[filter].resolution;
        const result = await fetchHistoricalData(
          stockSymbol,
          resolution,
          startDate,
          endDate
        );
        const formattedData = formatData(result);
        setData(formattedData);
      } catch (error) {
        setData([]);
        console.log(error);
      }
    };

    updateChartData();
  }, [filter]);

  // Calculate percentage change
  let percentageChange = '';
  if (data.length > 0) {
    const firstValue = parseFloat(data[0].value);
    const lastValue = parseFloat(data[data.length - 1].value);
    percentageChange = (((lastValue - firstValue) / firstValue) * 100).toFixed(
      2
    );
  }

  // Determine text color based on percentage change
  const textColor = percentageChange >= 0 ? 'green' : 'red';

  return (
    <Card>
      <div
        style={{
          position: 'absolute',
          top: '10px',
          left: '50%',
          transform: 'translateX(-50%)',
          color: textColor,
          fontSize: '24px',
        }}
      >
        <Text>{percentageChange && `${percentageChange}%`}</Text>
      </div>
      <ul className="flex absolute top-2 right-2 z-40">
        {Object.keys(chartConfig).map((item) => (
          <li key={item}>
            <ChartFilter
              text={item}
              active={filter === item}
              onClick={() => {
                setFilter(item);
              }}
            />
          </li>
        ))}
      </ul>
      <ResponsiveContainer width="100%" height={500}>
        <AreaChart data={data}>
          <defs>
            <linearGradient id="chartColor" x1="0" y1="0" x2="0" y2="1">
              <stop
                offset="5%"
                stopColor={'rgb(199 210 254)'}
                stopOpacity={0.8}
              />
              <stop
                offset="95%"
                stopColor={'rgb(199 210 254)'}
                stopOpacity={0}
              />
            </linearGradient>
          </defs>
          <Tooltip />
          <Area
            type="monotone"
            dataKey="value"
            stroke="#312e81"
            fill="rgb(199 210 254)"
            fillOpacity={1}
            strokeWidth={0.5}
          />
          <XAxis dataKey="date" />
          <YAxis domain={['dataMin', 'dataMax']} />
        </AreaChart>
      </ResponsiveContainer>
    </Card>
  );
};

export default Chart;
