import React from 'react';

const Header = ({ stockData }) => {
  return (
    <>
      <div className="flex flex-row justify-between">
        <div className="flex items-center">
          <div className="flex-shrink-0 h-20 w-20">
            <img src={stockData.logo} alt="Stock Logo" />
          </div>
          <h1 className="text-5xl">{stockData.name}</h1>
        </div>
        <div className="flex items-center pl-10">
          <span className="text-3xl flex items-center">
            ${stockData.price.toFixed(2)}
          </span>
          <span
            className={`text-3xl  ${
              stockData.change > 0 ? 'text-lime-500' : 'text-red-500'
            }  flex items-center pl-3`}
          >
            {stockData.change.toFixed(2)}{' '}
            <span className="ml-1">
              ({stockData.changePercent.toFixed(2)}%)
            </span>
          </span>
        </div>
        <div className="flex items-center justify-between pl-10 space-x-2">
          <div className="flex-shrink-0 h-10 w-10 mb-4 pt-2">
            <a
              href={`https://finance.yahoo.com/quote/${stockData.symbol}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/yahoo.PNG" alt="Yahoo" />
            </a>
          </div>
          <div className="flex-shrink-0 h-10 w-10 mb-4 pt-2">
            <a
              href={`https://quotes.fidelity.com/webxpress/popup_quote.phtml?SID_VALUE_ID=${stockData.symbol}&qlinks=fsl`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/fidelity.PNG" alt="Fidelity" />
            </a>
          </div>

          <div className="flex-shrink-0 h-10 w-10 mb-4 pt-2">
            <a
              href={`https://www.barrons.com/market-data/stocks/${stockData.symbol}&qlinks=fsl`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/barrons.PNG" alt="Barrons" />
            </a>
          </div>

          <div className="flex-shrink-0 h-10 w-10 mb-4 pt-2">
            <a
              href={`https://www.gurufocus.com/stock/${stockData.symbol}/summary`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/gurufocus.PNG" alt="Gurufocus" />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
