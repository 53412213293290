import { React, useState, useEffect } from 'react';
import axios from 'axios';

import { Badge, Table, Modal } from 'flowbite-react';
import Avatar from '../components/Avatar';
import CreatableSelect from 'react-select/creatable';

import Spinner from '../components/Spinner';
import DataTable from '../components/DataTable';
import { useNavigate, Link } from 'react-router-dom';

function Notes() {
  const [notes, setNotes] = useState([]);
  const [stockSymbol, setStockSymbol] = useState([]);

  const [loading, setLoading] = useState(true);
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);

  const [noteData, setNoteData] = useState({
    title: '',
    text: '',
    summary: '',
    sentiment: 'Neutral',
    data: {},
  });

  const [data, setData] = useState('');

  const [openModal, setOpenModal] = useState(false);

  async function addNote(noteData) {
    const newNoteRes = await axios.post(
      `${process.env.REACT_APP_API_URL}/notes/${stockSymbol}`,
      {
        ...noteData,
        tags: selectedTags.map((t) => t.value),
      }
    );

    setNotes([newNoteRes.data, ...notes]);
  }

  function handleSubmit(e) {
    e.preventDefault();
    addNote(noteData);
    setNoteData({
      title: '',
      text: '',
      summary: '',
      sentiment: 'Bullish',
      data: {},
    });
    setStockSymbol('');
    setOpenModal(false);
  }

  useEffect(() => {
    const getNotes = async () => {
      try {
        const result = await axios.get(
          `${process.env.REACT_APP_API_URL}/notes`
        );

        setLoading(false);
        setNotes(result.data);
      } catch (error) {
        console.log(error);
      }
    };

    const getTags = async () => {
      try {
        const result = await axios.get(`${process.env.REACT_APP_API_URL}/tags`);

        setLoading(false);
        setTags(
          result.data.map((t) => {
            return {
              label: t.tag,
              value: t.tag,
            };
          })
        );
      } catch (error) {
        console.log(error);
      }
    };

    getTags();
    getNotes();
  }, []);

  if (loading) {
    return <Spinner />;
  }

  const sentimentToColor = {
    Bullish: 'success',
    Bearish: 'failure',
    TBD: 'warning',
    Neutral: 'gray',
  };

  return (
    <div className="pt-8">
      <h2 class="text-5xl my-3 text-center text-gray-500">Notes</h2>
      <div class="flex flex-row justify-end mb-6">
        <button
          type="submit"
          class="inline-flex items-center pb-2.5 pl-20 text-white bg-indigo-700 hover:bg-indigo-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
          onClick={() => setOpenModal(true)}
        >
          Add note
        </button>
        <Modal
          size="7xl"
          dismissible
          show={openModal}
          onClose={() => setOpenModal(false)}
        >
          <Modal.Header>Add a Note</Modal.Header>
          <Modal.Body>
            <div className="space-y-6">
              <form
                class="space-y-4 md:space-y-6"
                onSubmit={handleSubmit}
                action="#"
              >
                <div>
                  <label
                    for="title"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Stock
                  </label>
                  <input
                    name="quantity"
                    id="quantity"
                    class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-indigo-500 dark:focus:border-indigo-500"
                    placeholder=""
                    value={stockSymbol}
                    onChange={(e) => setStockSymbol(e.target.value)}
                  />
                </div>

                <div>
                  <label
                    for="title"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Tags
                  </label>
                  <CreatableSelect
                    isMulti
                    options={tags}
                    onChange={(newValue) => setSelectedTags(newValue)}
                    onCreateOption={(newTag) => {
                      setTags([{ label: newTag, value: newTag }, ...tags]);
                      setSelectedTags([
                        ...selectedTags,
                        { label: newTag, value: newTag },
                      ]);
                    }}
                    value={selectedTags}
                  />
                </div>

                <div>
                  <label
                    for="title"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Title
                  </label>
                  <input
                    name="title"
                    id="title"
                    class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-indigo-500 dark:focus:border-indigo-500"
                    placeholder=""
                    value={noteData.title}
                    onChange={(e) => {
                      setNoteData({ ...noteData, title: e.target.value });
                    }}
                  />
                </div>
                <div>
                  <label
                    for="title"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Note
                  </label>

                  <textarea
                    id="comment"
                    rows="6"
                    value={noteData.text}
                    onChange={(e) => {
                      setNoteData({ ...noteData, text: e.target.value });
                    }}
                    class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-indigo-500 dark:focus:border-indigo-500"
                    required
                  ></textarea>
                </div>
                <div>
                  <label
                    for="summary"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Summary
                  </label>
                  <input
                    name="summary"
                    id="summary"
                    class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-indigo-500 dark:focus:border-indigo-500"
                    placeholder=""
                    value={noteData.summary}
                    onChange={(e) => {
                      setNoteData({ ...noteData, summary: e.target.value });
                    }}
                  />
                </div>
                <div>
                  <label
                    for="sentiment"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Sentiment
                  </label>
                  <select
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                    value={noteData.sentiment}
                    onChange={(e) =>
                      setNoteData({ ...noteData, sentiment: e.target.value })
                    }
                  >
                    <option value="Neutral">Neutral</option>
                    <option value="Bullish">Bullish</option>
                    <option value="Bearish">Bearish</option>
                    <option value="TBD">TBD</option>
                  </select>
                </div>
              </form>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="submit"
              class="bg-indigo-700 inline-flex items-center pb-2.5 pl-40 text-white hover:bg-indigo-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              onClick={handleSubmit}
            >
              Save
            </button>
            <button
              type="submit"
              class="inline-flex items-center pb-2.5 pl-40 text-indigo-700 bg-gray-300 border border-indigo-700 hover:bg-gray-500 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              onClick={() => setOpenModal(false)}
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>
      </div>
      <div className="overflow-x-auto">
        <Table>
          <Table.Head>
            <Table.HeadCell className="">Stock</Table.HeadCell>
            <Table.HeadCell className="">Title</Table.HeadCell>
            <Table.HeadCell className="">Sentiment</Table.HeadCell>
            <Table.HeadCell className="">Date</Table.HeadCell>
            <Table.HeadCell className="">User</Table.HeadCell>
          </Table.Head>
          <Table.Body className="divide-y">
            {notes.map((note) => (
              <Table.Row>
                <Table.Cell className="">
                  <div className="flex flex-wrap gap-2">
                    <div className="flex flex-wrap gap-2 items-center text-center">
                      <Link
                        to={`/stocks/${note.stock}`}
                        style={{ color: '#FFF' }}
                      >
                        <Badge color="gray">{note.stock}</Badge>
                      </Link>
                    </div>
                  </div>
                </Table.Cell>

                <Table.Cell className="items-center text-center">
                  <Link to={`/notes/${note.id}`} style={{ color: '#FFF' }}>
                    <div className="flex flex-wrap gap-2 items-center text-center text-xl text-black">
                      {note.title}
                    </div>
                  </Link>
                </Table.Cell>

                <Table.Cell className="items-center text-center ">
                  <div className="flex flex-wrap gap-2 items-center text-center ">
                    <Badge color={`${sentimentToColor[note.sentiment]}`}>
                      {note.sentiment}
                    </Badge>
                  </div>
                </Table.Cell>

                <Table.Cell className="text-gray-800">
                  {' '}
                  {new Intl.DateTimeFormat('en-US', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                  }).format(new Date(note.created_on))}
                </Table.Cell>

                <Table.Cell className="">
                  <Avatar
                    first_name={note.user.first_name}
                    last_name={note.user.last_name}
                  />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
    </div>
  );
}

export default Notes;
