import { React, useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import DataTable from '../components/DataTable';
import { Badge, Table, Modal } from 'flowbite-react';
import CreatableSelect from 'react-select/creatable';

import Spinner from '../components/Spinner';
import useApi from '../hooks/useApi';

export default function Watchlists() {
  const [watchlists, setWatchlists] = useState([]);
  const [loading, setLoading] = useState(true);

  const [stocks, setStocks] = useState([]);
  const [selectedStocks, setSelectedStocks] = useState([]);

  const [watchlistData, setWatchlistData] = useState({
    name: '',
  });

  const [data, setData] = useState('');

  const [openModal, setOpenModal] = useState(false);

  async function addWatchlist(watchlistData) {
    const newWatchlistRes = await axios.post(
      `${process.env.REACT_APP_API_URL}/watchlists/`,
      {
        ...watchlistData,
        stocks: selectedStocks.map((s) => s.value),
        // stocks: selectedStocks.map((t) => t.value), TODO
      }
    );

    setWatchlists([newWatchlistRes.data, ...watchlists]);
  }

  function handleSubmit(e) {
    e.preventDefault();
    addWatchlist(watchlistData);
    setWatchlistData({
      name: '',
    });
    setSelectedStocks([]);
    setOpenModal(false);
  }

  async function handleDelete(e, id) {
    e.preventDefault();
    setLoading(true);
    const res = await axios.delete(
      `${process.env.REACT_APP_API_URL}/watchlists/${id}`
    );

    setLoading(false);
    setWatchlists([...watchlists.filter((w) => w.id !== id)]);
  }

  const columns = [
    {
      key: 'name',
      value: 'Name',
      badge: true,
      linkPath: '/watchlists/',
      linkKey: 'id',
    },
    { key: 'stocks', value: 'Stocks', multiImages: true },
    // { key: 'created_on', value: 'Created' },
    { key: 'user', value: 'User' },
    {
      key: 'callback',
      value: '',
      onClick: handleDelete,
      params: ['id'],
    },
  ];

  useEffect(() => {
    const getWatchlists = async () => {
      try {
        const result = await axios.get(
          `${process.env.REACT_APP_API_URL}/watchlists`
        );

        setLoading(false);
        setWatchlists(result.data);
      } catch (error) {
        console.log(error);
      }
    };

    getWatchlists();
  }, []);

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="pt-8">
      <h2 class="text-5xl my-3 text-center text-gray-500">Watchlists</h2>
      <div class="flex flex-row justify-end mb-6">
        <button
          type="submit"
          class="inline-flex items-center pb-2.5 pl-20 text-white bg-indigo-700 hover:bg-indigo-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
          onClick={() => setOpenModal(true)}
        >
          Add watchlist
        </button>
        <Modal
          size="7xl"
          dismissible
          show={openModal}
          onClose={() => setOpenModal(false)}
        >
          <Modal.Header>Add a Watchlist</Modal.Header>
          <Modal.Body>
            <div className="space-y-6">
              <form
                class="space-y-4 md:space-y-6"
                onSubmit={handleSubmit}
                action="#"
              >
                <div>
                  <label
                    for="title"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Watchlist Name
                  </label>
                  <input
                    name="quantity"
                    id="quantity"
                    class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-indigo-500 dark:focus:border-indigo-500"
                    placeholder=""
                    value={watchlistData.name}
                    onChange={(e) =>
                      setWatchlistData({
                        ...watchlistData,
                        name: e.target.value,
                      })
                    }
                  />
                </div>

                <div>
                  <label
                    for="title"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Stocks
                  </label>
                  <CreatableSelect
                    isMulti
                    options={stocks}
                    onChange={(newValue) => setSelectedStocks(newValue)}
                    onCreateOption={(newStock) => {
                      setStocks([
                        { label: newStock, value: newStock },
                        ...stocks,
                      ]);
                      setSelectedStocks([
                        ...selectedStocks,
                        { label: newStock, value: newStock },
                      ]);
                    }}
                    value={selectedStocks}
                  />
                </div>
              </form>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="submit"
              class="bg-indigo-700 inline-flex items-center pb-2.5 pl-40 text-white hover:bg-indigo-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              onClick={handleSubmit}
            >
              Save
            </button>
            <button
              type="submit"
              class="inline-flex items-center pb-2.5 pl-40 text-indigo-700 bg-gray-300 border border-indigo-700 hover:bg-gray-500 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              onClick={() => setOpenModal(false)}
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>
      </div>
      {/* <WatchlistsTable watchlists={watchlists} activeOnly={true} showSymbol={true} /> */}
      <DataTable rows={watchlists} columns={columns} />
    </div>
  );
}
