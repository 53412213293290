import axios from 'axios';

export const login = async (email, password) => {
  // const res = await axios.get(`${process.env.REACT_APP_API_URL}/users`);
  // console.log(res);

  const response = await axios.post(`${process.env.REACT_APP_API_URL}/login`, {
    email,
    password,
  });

  const token = response.data.access_token;
  let user = response.data.user;
  user.token = token;

  if (token) {
    localStorage.setItem('user', JSON.stringify(user));
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }
  return user;
};

export const signup = async (
  username,
  email,
  password,
  firstName,
  lastName
) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/login`, {
    username,
    password,
    email,
    firstName,
    lastName,
  });

  const token = response.data.access_token;
  let user = response.data.user;
  user.token = token;

  if (token) {
    localStorage.setItem('user', JSON.stringify(user));
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }

  return user;
};

export const logout = () => {
  localStorage.removeItem('user');
};

export const isAuthenticated = () => {
  const user = localStorage.getItem('user');
  if (!user) {
    return {};
  }
  return JSON.parse(user);
};
